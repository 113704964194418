import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useHistory, useLocation } from "react-router-dom";
import Swal from "sweetalert2";
import "../css/layout.css";
import "../css/login.css";

export default function Login() {
  const history = useHistory();

  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const nodeMode = process.env.NODE_ENV;
  const baseUrl =
    nodeMode === "development" ? beckendLocalApiUrl : beckendLiveApiUrl;

  const [WebSitesettings, setWebsiteSettings] = useState("");
  const [Phone, setPhone] = useState("");
  const [otp, setOtp] = useState(false);
  const [otpValues, setOtpValues] = useState(new Array(6).fill(""));
  const [secretCode, setSecretCode] = useState("");
  const [referral, setReferral] = useState("");
  const location = useLocation();

  useEffect(() => {
    const fetchData = async () => {
      const response = await axios.get(baseUrl + "settings/data");
      setWebsiteSettings(response.data);
    };
    fetchData();
  }, [baseUrl]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const referralCode = params.get("referral");
    setReferral(referralCode || "");
  }, [location]);

  const handleClick = async (e) => {
    e.preventDefault();

    if (!Phone) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Please enter your phone number",
      });
    } else if (Phone.length !== 10) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Please enter a valid phone number",
      });
    } else {
      try {
        const response = await axios.post(baseUrl + "login", {
          Phone,
          referral,
        });
        if (response.data.status === 101) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: response.data.msg,
          });
        } else if (response.data.status === 200) {
          setOtp(true);
          setSecretCode(response.data.secret);
          if (response.data.myToken) {
            Swal.fire({
              icon: "success",
              title: "OTP",
              text: "Demo Account Testing OTP: " + response.data.myToken,
            });
          }
        }
      } catch {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong",
        });
      }
    }
  };

  const handleOtpChange = (element, index) => {
    const value = element.value.replace(/[^0-9]/g, ""); // Only allow numeric input
    const updatedOtp = [...otpValues];
    updatedOtp[index] = value;
    setOtpValues(updatedOtp);

    // Move to the next input if the current one is filled
    if (value && element.nextSibling) {
      element.nextSibling.focus();
    }
  };

  const verifyOtp = async (e) => {
    e.preventDefault();
    const enteredOtp = otpValues.join("");
    if (enteredOtp.length !== 6) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Please enter the full OTP",
      });
      return;
    }

    try {
      const response = await axios.post(baseUrl + "login/finish", {
        Phone,
        twofactor_code: enteredOtp,
        referral,
        secretCode,
      });
      if (response.data.status === 101) {
        Swal.fire({ icon: "error", title: "Oops...", text: response.data.msg });
      } else if (response.data.status === 200) {
        localStorage.setItem("token", response.data.token);
        window.location.reload(true);
        setTimeout(() => history.push("/Games"), 1000);
      }
    } catch {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
      });
    }
  };

  return (
    <div className="leftContainer" style={{ minHeight: "100vh" }}>
      <div className="main-area bg-dark">
        <div style={{ overflowY: "hidden" }}>
          <div
            className="position-absolute w-100 center-xy mx-auto"
            style={{ top: "30%", zIndex: 9 }}
          >
            <div className="d-flex  font-15 mb-4">Sign in</div>
            <div
              className="bg-white px-3 cxy flex-column"
              style={{ width: "85%", height: "60px", borderRadius: "5px" }}
            >
              <div className="input-group mb-2">
                <div className="input-group-prepend">
                  <div
                    className="input-group-text"
                    style={{
                      width: "100px",
                      backgroundColor: "#e9ecef",
                      border: "1px solid #d8d6de",
                    }}
                  >
                    +91
                  </div>
                </div>
                <input
                  className="form-control"
                  name="mobile"
                  type="tel"
                  placeholder="Mobile number"
                  onChange={(e) => setPhone(e.target.value)}
                  maxLength={10}
                  style={{ borderRadius: "4px" }}
                />
              </div>
            </div>
            {otp && (
              <div
                className="otp-container mt-4"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  gap: "2px", // 2px gap between columns
                }}
              >
                {otpValues.map((value, index) => (
                  <input
                    key={index}
                    type="text"
                    maxLength="1"
                    value={value}
                    onChange={(e) => handleOtpChange(e.target, index)}
                    onFocus={(e) => e.target.select()}
                    style={{
                      width: "40px",
                      height: "50px", // Slightly taller for easier interaction
                      textAlign: "center",
                      fontSize: "20px",
                      fontWeight: "bold",
                      color: "#333", // Neutral color for better readability
                      backgroundColor: "#f9f9f9", // Light background for contrast
                      border: "1px solid #ccc",
                      borderRadius: "8px", // Smooth rounded corners
                      boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.1)", // Subtle shadow for a modern look
                      transition: "border 0.3s ease, box-shadow 0.3s ease",
                    }}
                    onFocus={(e) => {
                      e.target.style.border = "1px solid #007bff";
                      e.target.style.boxShadow =
                        "0px 2px 6px rgba(0, 123, 255, 0.3)";
                    }}
                    onBlur={(e) => {
                      e.target.style.border = "1px solid #ccc";
                      e.target.style.boxShadow =
                        "0px 1px 3px rgba(0, 0, 0, 0.1)";
                    }}
                  />
                ))}
              </div>
            )}
            {!otp && (
              <button className="Login-button cxy mt-4" onClick={handleClick}>
                Continue
              </button>
            )}
            {otp && (
              <button className="Login-button cxy mt-4" onClick={verifyOtp}>
                Verify
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
